
import { computed, defineComponent } from "vue";
import VueApexCharts from "vue3-apexcharts";

import AccountBase from "@/views/crafted/account/Account-Base.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Account-Overview",
  components: {
    AccountBase,
    VueApexCharts
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.currentUser);
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));


    return {
      user,
      currentLanguage
    };
  },
});
